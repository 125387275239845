import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ApplyButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--Monad-purple, #836ef9);
  background: var(--Monad-purple, #836ef9);
  transition: all 0.08s ease;

  &:hover {
    background: #db5afd;
    border-color: #db5afd;
  }

  width: 100%;
  height: 49px;

  color: var(--White, #fff);
  text-align: center;
  /* body */
  font-family: "Press Start 2P";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.056px;
  text-transform: uppercase;
  margin-top: 54px;

  @media (min-width: 768px) {
    font-size: 14px;
    margin-top: 56px;

    width: 160px;
  }
`;

const TextContainer = styled.div`
  max-width: 740px;
  overflow: auto;
  z-index: 2;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 1rem;
  }
`;

const Header = styled.h1`
  font-weight: 700;
  font-family: "Chakra Petch", sans-serif;
  font-size: 96px;
  letter-spacing: -0.384px;
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 0;

    font-size: 96px;
  }
`;

const Table = styled.div`
  font-family: "Press Start 2P";
  display: grid;
  max-width: 740px;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.056px;
  text-transform: uppercase;

  grid-template-columns: 1fr;
  justify-items: stretch;

  @media (min-width: 768px) {
    row-gap: 0.75rem;
    font-size: 14px;
    justify-items: flex-end;
    grid-template-columns: max-content 1fr;
  }
`;

const Left = styled.p`
  line-height: 1.2;
`;
const Right = styled.p`
  line-height: 1.5;
  margin-bottom: 2rem;
  margin-top: 8px;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const Bottom = styled.div`
  color: var(--White, #fff);
  /* body */
  font-family: "Press Start 2P";
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.056px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin-top: 54px;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 56px;
    font-size: 14px;
  }
`;

const BottomText = styled.p`
  text-align: center;
  color: var(--White, #fff);
  /* body */
  font-family: "Press Start 2P";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.056px;
  text-transform: uppercase;

  &:not(:first-of-type) {
    margin-top: 0.6rem;
  }

  @media (min-width: 768px) {
    font-size: 14px;

    text-align: left;

    &:not(:first-of-type) {
      margin-top: 0rem;
    }
  }
`;

const loadScript = () => {
  const script = document.createElement("script");
  script.src = "//embed.typeform.com/next/embed.js";
  script.onload = function (e) {
    console.log("script loaded", e);
  };
  document.body.appendChild(script);
};

const Board = () => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    loadScript();
  }, []);

  return (
    <>
      <img alt="BG" src="./img/BG-desktop.png" className="bg_image" />
      <img alt="desktop " src="./img/socks.png" className="socks_image" />
      <img alt="egirl" src="./img/egirl.png" className="egirl_image" />

      <TextContainer>
        <Header>OCT / 04 / 2024</Header>
        <Table>
          <Left>10:30 - 11:30</Left>
          <Right>DANNY (MONAD)</Right>

          <Left>11:30 - 12:45</Left>
          <Right>CHARLIE NOYES (PARADIGM)</Right>

          <Left>12:45 - 02:00</Left>
          <Right>KUURO (OHBABY, MONSTERCAT)</Right>

          <Left>02:00 - 04:00</Left>
          <Right>BHM (DELPHI DIGITAL)</Right>
        </Table>

        <Bottom>
          <BottomText>
            VENUE:
            <br /> TO BE ANNOUNCED
          </BottomText>
          <BottomText>NEW YORK</BottomText>
        </Bottom>

        <ApplyButton
          data-tf-popup="U9n5rRTk"
          data-tf-iframe-props="title=My typeform"
          data-tf-medium="snippet"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {`MEOW ${hovered ? "~" : "NOW"}`}
        </ApplyButton>
      </TextContainer>
    </>
  );
};

export default Board;
