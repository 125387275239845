import { createContext, FC, ReactNode, useCallback, useContext, useReducer } from 'react';
import { ModalTypes, ModalActionTypes } from '../types/enums';

interface State {
  modal: ModalTypes,
}

interface IContext {
  state: State,
  openModal: (type: ModalTypes) => void,
  closeModal: () => void,
}

const initialState: State = {
  modal: ModalTypes.NONE,
}

const Context = createContext<IContext>({
  state: initialState,
  openModal: () => {},
  closeModal: () => {},
})

export const useModal = () => {
  const { state: { modal }, openModal, closeModal } = useContext(Context);

  return {
    modal,
    openModal,
    closeModal,
  };
}

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case ModalActionTypes.UPDATE_MODAL_TYPE:
      return {
        ...state,
        modal: action.modalType
      }
    default:
      throw new Error();
  }
}

const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = useCallback((type: ModalTypes) => {
    dispatch({
      type: ModalActionTypes.UPDATE_MODAL_TYPE,
      modalType: type,
    });
  }, []);

  const closeModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.UPDATE_MODAL_TYPE,
      modalType: ModalTypes.NONE,
    });
  }, [])

  const context = {
    state,
    openModal,
    closeModal,
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default ModalProvider;